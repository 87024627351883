<template>
  <div class="gate-machine-dialog">
    <Wdialog
      ref="gateMachineDialog"
      :title="gateMachineTitle"
      width="50%"
      @wConfirm="handleAddGateMachine"
    >
      <el-form
        ref="gateMachineFormRef"
        :model="gateMachineFormData"
        :rules="gateMachineFormRules"
        label-position="top"
        class="gate-machine-form"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="name" label="闸机名称">
              <el-input
                v-model="gateMachineFormData.name"
                autocomplete="off"
                placeholder="请输入闸机名称"
                clearable
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="resources" label="所属资源">
              <el-input
                v-model="gateMachineFormData.resources"
                autocomplete="off"
                placeholder="请输入所属资源"
                clearable
              /> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="gate_position_id" label="闸机位置">
              <el-select
                v-model="gateMachineFormData.gate_position_id"
                placeholder="请选择闸机位置"
              >
                <el-option
                  v-for="item in gateLocationOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="sn" label="控制机序号">
              <el-input
                v-model="gateMachineFormData.sn"
                autocomplete="off"
                placeholder="请输入控制机序号"
                clearable
              /> </el-form-item
          ></el-col>

          <el-col :span="8">
            <el-form-item prop="status" label="启用状态">
              <el-select
                v-model="gateMachineFormData.status"
                placeholder="请选择启用状态"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="gate_type_id" label="闸机类别">
              <el-select
                v-model="gateMachineFormData.gate_type_id"
                placeholder="请选择闸机类别"
              >
                <el-option
                  v-for="item in gateTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="charge" label="是否计费">
              <el-select
                v-model="gateMachineFormData.charge"
                placeholder="请选择是否计费"
              >
                <el-option
                  v-for="item in isChargeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8" v-if="needParking">
            <el-form-item prop="project_id" label="所属项目">
              <el-select
                v-model="gateMachineFormData.project_id"
                placeholder="请选择所属项目"
                @change="changeSelectProject"
              >
                <el-option
                  v-for="item in projectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="needParking">
            <el-form-item prop="carpark_id" label="所属停车场">
              <el-select
                :disabled="!gateMachineFormData.project_id"
                v-model="gateMachineFormData.carpark_id"
                placeholder="请选择所属停车场"
              >
                <el-option
                  v-for="item in parkingOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { ElMessage } from "element-plus";
import Wdialog from "@/components/wDialog/wDialog.vue";
import API from "@/plugins/api.js";
import { checkStr } from "@/utils/common.js";

export default {
  components: {
    Wdialog,
  },
  props: {
    needParking: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const gateLocationOptions = ref([]);
    const gateTypeOptions = ref([]);
    const statusOptions = ref([]);
    const isChargeOptions = ref([]);
    const projectOptions = ref([]);
    const parkingOptions = ref([]);
    const gateMachineFormData = ref({
      name: "",
      carpark_id: "",
      resources: "",
      sn: "",
      status: "",
      gate_position_id: "",
      gate_type_id: "",
      charge: "",
      project_id: "",
    });
    function changeSelectProject() {
      gateMachineFormData.value.carpark_id = "";
    }
    watch(
      () => gateMachineFormData.value.project_id,
      (v) => {
        if (v) {
          API.getParkingOptions({ project_id: v }).then((res) => {
            parkingOptions.value = res;
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    onMounted(() => {
      API.getProjectOptions({}).then((res) => {
        if (res.length) {
          projectOptions.value = res;
        }
      });
      API.getGateLocationOptions().then((res) => {
        gateLocationOptions.value = res;
      });
      API.getGateTypeOptions().then((res) => {
        gateTypeOptions.value = res;
      });
      API.getGateStatusOptions().then((res) => {
        if (res.length) {
          statusOptions.value = [];
          res.forEach((el, index) => {
            statusOptions.value.push({
              label: el,
              value: index,
            });
          });
        }
      });
      API.getGateIsChargeOptions().then((res) => {
        if (res.length) {
          isChargeOptions.value = [];
          res.forEach((el, index) => {
            isChargeOptions.value.push({
              label: el,
              value: index,
            });
          });
        }
      });
    });
    const gateMachineDialog = ref(null);
    const gateMachineFormRef = ref(null);
    const commonRules = {
      name: [{ required: true, message: "请选择闸机名称", trigger: "change" }],
      sn: [{ required: true, message: "请输入控制机序号", trigger: "blur" }],
      gate_position_id: [
        { required: true, message: "请选择闸机位置", trigger: "blur" },
      ],
      gate_type_id: [
        {
          required: true,
          message: "请选择闸机类别",
          trigger: "blur",
        },
      ],
      charge: [
        {
          required: true,
          message: "请选择是否计费",
          trigger: "blur",
        },
      ],
    };
    const gateMachineFormRules = ref({});

    props.needParking
      ? (gateMachineFormRules.value = {
          ...commonRules,
          carpark_id: [
            { required: true, message: "请选择所属停车场", trigger: "change" },
          ],
          project_id: [
            { required: true, message: "请选择所属项目", trigger: "change" },
          ],
        })
      : (gateMachineFormRules.value = {
          ...commonRules,
        });

    function handleAddGateMachine() {
      if (gateMachineFormRef.value) {
        gateMachineFormRef.value.validate((valid) => {
          if (valid) {
            const data = JSON.parse(JSON.stringify(gateMachineFormData.value));
            const gateLocation = gateLocationOptions.value.find(
              (item) => item.id === gateMachineFormData.value.gate_position_id
            );

            const gateType = gateTypeOptions.value.find(
              (item) => item.id === gateMachineFormData.value.gate_type_id
            );

            data["gateLocationName"] = gateLocation.name;
            data["gateTypeName"] = gateType.name;
            if (data.sn && !checkStr(data.sn, "letterNumber")) {
              ElMessage.warning({
                message: "控制机序号必须是字母和数字！",
              });
              return false;
            }
            emit("submit", data);
          }
        });
      }
    }
    const gateMachineTitle = ref("");
    function openDialog(data) {
      if (data) {
        const lastData = JSON.parse(JSON.stringify(data));
        gateMachineTitle.value = "编辑闸机";
        lastData.status == true ? (lastData.status = 1) : (lastData.status = 0);
        gateMachineFormData.value = lastData;
        //编辑
      } else {
        //新增
        gateMachineTitle.value = "新增闸机";
        gateMachineFormData.value = {
          name: "",
          carpark_id: "",
          resources: "",
          sn: "",
          status: "",
          gate_position_id: "",
          gate_type_id: "",
          charge: "",
          project_id: "",
        };
      }

      gateMachineDialog.value.show();
    }
    function closeDialog() {
      gateMachineDialog.value.close();
    }
    return {
      gateMachineFormData,
      gateMachineFormRules,
      gateMachineFormRef,
      handleAddGateMachine,
      gateTypeOptions,
      openDialog,
      gateMachineDialog,
      statusOptions,
      isChargeOptions,
      gateLocationOptions,
      parkingOptions,
      projectOptions,
      closeDialog,
      gateMachineTitle,
      changeSelectProject,
    };
  },
};
</script>

<style lang="scss">
.gate-machine-dialog {
  .gate-machine-form {
    padding-bottom: 150px;
  }
}
</style>
